var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "error404"
  }, [_c('div', {
    staticClass: "error404-body-content"
  }, [_c('Card', [_c('div', {
    staticClass: "error404-body-content-title"
  }, [_vm._v("4"), _c('span', [_c('Icon', {
    attrs: {
      "type": "ios-navigate-outline"
    }
  })], 1), _vm._v("4")]), _c('p', {
    staticClass: "error404-body-content-message"
  }, [_vm._v("YOU  LOOK  LOST")]), _c('div', {
    staticClass: "error404-btn-content"
  }, [_c('Button', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "size": "large",
      "type": "text"
    },
    on: {
      "click": _vm.goHome
    }
  }, [_vm._v("返回首页")]), _c('Button', {
    staticStyle: {
      "width": "200px",
      "margin-left": "40px"
    },
    attrs: {
      "size": "large",
      "type": "primary"
    },
    on: {
      "click": _vm.backPage
    }
  }, [_vm._v("返回上一页")])], 1)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };